console.log("Hello World");

// windowed button
const btnСollapse = document.querySelector(".btn-collapse");
// nav
const nav = document.querySelector("nav.nav");

// windowed flag for throttling
let windowedFlag = 0;

// check if the windowed mode is open or not
let isWindowedOpenFlag =
  sessionStorage.getItem("isWindowedOpenFlag") === "true" ? true : false;

// check if the windowed mode is open or not and toggle the styles for left\right part
if (isWindowedOpenFlag && nav) {
  nav.classList.add("collapsed");
  btnСollapse.classList.add("btn-collapse--active");
}

// windowed button event listener
btnСollapse?.addEventListener("click", function (e) {
  e.stopPropagation();

  if (windowedFlag == 0 && e.target.dataset.collapse) {
    toggleWindow();
    windowedFlag = 1;
    setTimeout(() => {
      windowedFlag = 0;
    }, 1000);
  } else {
    return;
  }
});

// toggle windowed mode
function toggleWindow() {
  if (btnСollapse.classList.contains("btn-collapse--active")) {
    btnСollapse.classList.remove("btn-collapse--active");
    nav.classList.remove("collapsed");

    sessionStorage.setItem("isWindowedOpenFlag", "false");
  } else {
    nav.classList.add("collapsed");
    btnСollapse.classList.add("btn-collapse--active");

    sessionStorage.setItem("isWindowedOpenFlag", "true");
  }
}
// -------------------------------------
var options = {
  series: [
    {
      name: "Доход: ",
      data: [
        [1, "0"],
        [2, "15000 руб."],
        [3, "30000 руб."],
        [4, "45000 руб."],
        [5, "60000 руб."],
        [6, "75000 руб."],
        [7, "70000 руб."],
        [8, "55000 руб."],
        [9, "5000 руб."],
        [10, "15000 руб."],
        [11, "20000 руб."],
        [12, "48000 руб."],
      ],
    },
    // {
    //   name: "Литров: ",
    // },
  ],
  chart: {
    height: 350,
    type: "area",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  colors: ["#4FD1C5", "#4FD1C5", "#23f1dd"],

  xaxis: {
    type: "text",

    categories: [
      "Янв",
      "Фев",
      "Мар",
      "Апр",
      "Май",
      "Июн",
      "Июл",
      "Авг",
      "Сен",
      "Окт",
      "Ноя",
      "Дек",
    ],
  },
  tooltip: {
    x: {
      format: "dd MMM yyyy",
    },
  },
};

let cartGraph = document.querySelector(".chart__graph-init");

if (cartGraph) {
  let chart = new ApexCharts(cartGraph, options);

  chart.render();
}

// MODALS
// get dialog in current page
document.getElementsByTagName("dialog")[0]?.addEventListener("close", (e) => {
  // if close modal - remove overflow
  document.body.style.overflow = "auto";
});

// button - open modal
let openModalForAddPlane = document.getElementById("openModalForAddPlane");
let openModalForEditPlaneAll = document.querySelectorAll(
  "#openModalForEditPlane"
);
let openModalForAddPartner = document.getElementById("openModalForAddPartner");
let openModalAddCard = document.getElementById("openModalAddCard");
let openModalCancelReplacementAll = document.querySelectorAll(
  "#openModalCancelReplacement"
);
// get button in current page - close modal
let closeButton = document.querySelectorAll(".modal .close-button");

// event for close button
// closeButton?.addEventListener("click", (e) => {
//   let currentModal = document.getElementsByTagName("dialog");
//   currentModal[0]?.close();
// });

// event for close button's
closeButton?.forEach((el) => {
  el?.addEventListener("click", (e) => {
    let currentModal = document.querySelector("dialog[open]");
    currentModal?.close();
  });
});

// open modal for add plane
// button event add plane
openModalForAddPlane?.addEventListener("click", (e) => {
  let modalAddPlane = document.getElementById("modalAddPlane");
  modalAddPlane?.showModal();
  document.body.style.overflow = "hidden";
});
// buttons event edit plane
openModalForEditPlaneAll?.forEach((el) => {
  el?.addEventListener("click", (e) => {
    e.stopPropagation();
    let idPlane = e.target.dataset.id;
    let titlePlane = e.target.dataset.title;
    let waterPlane = e.target.dataset.water;
    let pricePlane = e.target.dataset.price;
    let active = e.target.dataset.active;
    let modalEditPlane = document.getElementById("modalEditPlane");
    modalEditPlane.querySelector("input[name=id]").value = idPlane;
    modalEditPlane.querySelector("input[name=title]").value = titlePlane;
    modalEditPlane.querySelector("input[name=water]").value = waterPlane;
    modalEditPlane.querySelector("input[name=price]").value = pricePlane;
    modalEditPlane.querySelector("input[type=checkbox]").checked =
      active === "true" ? true : false;

    modalEditPlane?.showModal();
    document.body.style.overflow = "hidden";
  });
});
// button event add partner
openModalForAddPartner?.addEventListener("click", (e) => {
  let modalAddPartner = document.getElementById("modalAddPartner");
  modalAddPartner?.showModal();
  document.body.style.overflow = "hidden";
});
// button event add terminal
openModalAddCard?.addEventListener("click", (e) => {
  let partnerLogin = e.target.dataset.partner;

  let modalAddCard = document.getElementById("modalAddCard");

  modalAddCard.querySelector("input[name=login]").value = partnerLogin;

  modalAddCard?.showModal();
  document.body.style.overflow = "hidden";
});
// button event cancel replacement
openModalCancelReplacementAll?.forEach((el) => {
  el?.addEventListener("click", (e) => {
    let idReplacement = e.target.dataset.id;
    let procCount = e.target.dataset.proc;
    let modalCancelReplacement = document.getElementById(
      "modalCancelReplacement"
    );
    modalCancelReplacement.querySelector("p.proc-count span").innerHTML =
      procCount;
    modalCancelReplacement.querySelector("input[name=idReplacement]").value =
      idReplacement;
    modalCancelReplacement?.showModal();
    document.body.style.overflow = "hidden";
  });
});

// modal success. open after get response
// let s = document.getElementById("modalSuccess");
// s?.showModal();

// copy api key
const apiKeyInput = document.getElementById("apiKey");
const btnCopyApiKey = document.getElementById("copyApiKey");

btnCopyApiKey?.addEventListener("click", () => {
  apiKeyInput?.select();
  apiKeyInput?.setSelectionRange(0, 99999);
  navigator.clipboard.writeText(apiKeyInput.value);
});
// end copy api key

// generate password button
let generatePassword = document.getElementById("generatePassword");

// generate password button event
generatePassword?.addEventListener("click", (e) => {
  let inputPasswordGenerate = document.querySelector(
    ".input-password-generate"
  );

  inputPasswordGenerate.value = randomPassword(10);
});

// generate password function
function randomPassword(length) {
  var chars =
    "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";

  var pass = "";

  for (var x = 0; x < length; x++) {
    var i = Math.floor(Math.random() * chars.length);
    pass += chars.charAt(i);
  }
  return pass;
}
